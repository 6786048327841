import React from "react";

// Admin Imports
import SignIn from "views/auth/SignIn";
import SignUp from "views/auth/SignUp";
import PersonnelInfo from "views/admin/user/PersonnelInfo";
import OrganizationInfo from "views/admin/user/OrganizationInfo";
import AddUser from "views/admin/user/UserForm";


// Icon Imports
import {
  MdHome,
  MdBarChart,
  MdPerson,
  MdLock,
  MdSettings,
  MdChangeCircle,
  MdOutlinePriceChange,
  MdHistory,
  MdAddToPhotos,
  MdDomainAdd,
  MdOutlinePendingActions,
  MdCancel,
  MdOutlineFreeCancellation,
  MdDoneAll,
  MdOutlineDone,
  MdAutorenew,
  MdFreeCancellation,
  MdOutlineAssignmentReturn,
  MdOutlineAnalytics,
} from "react-icons/md";
import AllotementRequestForm from "views/admin/allotementRequest/AllotementRequestForm";
import RequestForResources from "views/admin/allotementRequest/RequestForResources";
import RequestDetails from "views/admin/RequestDetailPage/RequestDetails";
import AddResources from "views/admin/resources/AddResourcesForm";
import AddFromCsv from "views/admin/addFromCsv/addFRomCsv";

import Users from "views/admin/user/Users";
import Resources from "views/admin/resources/Resources";
import ShopForm from "views/admin/resources/resourceForms/ShopForm";
import MultipurposeHallForm from "views/admin/resources/resourceForms/MultiPurposeHallForm";
import OfficerAndStaffClubForm from "views/admin/resources/resourceForms/OfficerAndStuffClubForm";
import QuarterForm from "views/admin/resources/resourceForms/QuarterForm";
import { Role } from "views/customTypes/types";
import { OtpScreen } from "views/auth/OtpScreen";
import Dashboard from "views/admin/dashBoard/Dashboard";
import { Charges } from "views/admin/settings/Charges";
import { UserReport } from "views/admin/settings/UserReport";
import { AllUserRequests } from "views/admin/allotementRequest/AllUserRequests";
import ForgotPassword from "views/auth/ForgotPassword";
import { UserDetails } from "views/admin/user/UserDetails";

const routes = [
  //* user ====================>

  {
    name: "Personnel Information",
    layout: "/admin",
    path: "personnel_info",
    sideBarDisp: true,
    icon: <MdPerson className="h-6 w-6" />,
    component: <PersonnelInfo />,
    excludeFor: [
      Role.CLEARK,
      Role.ADMIN,
      Role.MANAGER,
      Role.FINANCE,
      Role.HR_ADMIN,
    ],
  },
  {
    name: "Organization Information",
    layout: "/admin",
    icon: <MdDomainAdd className="h-6 w-6" />,
    path: "organization_info",
    component: <OrganizationInfo />,
    sideBarDisp: true,
    excludeFor: [
      Role.CLEARK,
      Role.ADMIN,
      Role.MANAGER,
      Role.FINANCE,
      Role.HR_ADMIN,
    ],
  },
  {
    name: "Profile",
    layout: "/admin",
    icon: <MdDomainAdd className="h-6 w-6" />,
    path: "profile",
    component: <UserDetails />,
    sideBarDisp: false,
    excludeFor: [],
  },

  {
    name: "Resource Allotment Request",
    layout: "/admin",
    icon: <MdAddToPhotos className="h-6 w-6" />,
    path: "resource_allotement_request",
    component: <AllotementRequestForm />,
    sideBarDisp: true,
    excludeFor: [
      Role.CLEARK,
      Role.ADMIN,
      Role.MANAGER,
      Role.FINANCE,
      Role.HR_ADMIN,
    ],
  },

  {
    name: "All Requests",
    layout: "/admin",
    icon: <MdHistory className="h-6 w-6" />,
    path: "all_requests",
    component: <AllUserRequests />,
    sideBarDisp: true,
    excludeFor: [
      Role.CLEARK,
      Role.ADMIN,
      Role.MANAGER,
      Role.FINANCE,
      Role.HR_ADMIN,
    ],
  },
  //* user route end

  {
    name: "Dashboard",
    layout: "/admin",
    path: "dashboard",
    icon: <MdHome className="h-6 w-6" />,
    component: <Dashboard />,
    sideBarDisp: true,
    excludeFor: [Role.USER],
  },

  //?for cleark
  {
    name: "Request Of Resources",
    layout: "/admin",
    icon: <MdOutlineAnalytics className="h-6 w-6" />,
    path: "request_for_resources",
    component: <RequestForResources />,
    sideBarDisp: true,
    excludeFor: [Role.USER, Role.ADMIN, Role.FINANCE],
  },

  {
    name: "Renewal Requeset",
    layout: "/admin",
    icon: <MdAutorenew className="h-6 w-6" />,
    path: "renewal_requests",
    component: <RequestForResources />,
    sideBarDisp: true,
    excludeFor: [Role.USER, Role.ADMIN, Role.FINANCE, Role.MANAGER],
  },

  {
    name: "Vacant Resource Requests",
    layout: "/admin",
    icon: <MdOutlineAssignmentReturn className="h-6 w-6" />,
    path: "vacant_resource_request",
    component: <RequestForResources />,
    sideBarDisp: true,
    excludeFor: [Role.USER],
  },

  {
    name: "Change Resource Requeset",
    layout: "/admin",
    icon: <MdChangeCircle className="h-6 w-6" />,
    path: "change_resource_request",
    component: <RequestForResources />,
    sideBarDisp: true,
    excludeFor: [Role.USER, Role.ADMIN, Role.FINANCE, Role.MANAGER],
  },

  // todo change name
  {
    name: "Manager Approval Pending",
    layout: "/admin",
    icon: <MdOutlinePendingActions className="h-6 w-6" />,
    path: "manager_approval_pending",
    component: <RequestForResources />,
    sideBarDisp: true,
    excludeFor: [Role.USER, Role.MANAGER, Role.ADMIN, Role.FINANCE],
  },
  {
    name: "Manager Approval Pending(vacant)",
    layout: "/admin",
    icon: <MdOutlinePendingActions className="h-6 w-6" />,
    path: "manager_approval_vacant_pending",
    component: <RequestForResources />,
    sideBarDisp: true,
    excludeFor: [Role.USER, Role.MANAGER, Role.ADMIN, Role.FINANCE],
  },

  {
    name: "Clearification Pending",
    layout: "/admin",
    icon: <MdOutlinePendingActions className="h-6 w-6" />,
    path: "clearification_pending",
    component: <RequestForResources />,
    sideBarDisp: true,
    excludeFor: [Role.USER, Role.ADMIN, Role.FINANCE],
  },
  //?for manger
  {
    name: "Manager Approved Requests",
    layout: "/admin",
    icon: <MdOutlineDone className="h-6 w-6" />,
    path: "manager_approved_requests",
    component: <RequestForResources />,
    sideBarDisp: true,
    excludeFor: [Role.USER, Role.ADMIN, Role.FINANCE],
  },
  {
    name: "Payment Verification Pending",
    layout: "/admin",
    icon: <MdOutlinePendingActions className="h-6 w-6" />,
    path: "payment_verification_pending",
    component: <RequestForResources />,
    sideBarDisp: true,
    excludeFor: [Role.USER, Role.ADMIN],
  },
  {
    name: "Payment Verification Approved",
    layout: "/admin",
    icon: <MdOutlineDone className="h-6 w-6" />,
    path: "payment_verification_approved",
    component: <RequestForResources />,
    sideBarDisp: true,
    excludeFor: [Role.USER, Role.ADMIN],
  },
  {
    name: "Payment Verification Rejected",
    layout: "/admin",
    icon: <MdFreeCancellation className="h-6 w-6" />,
    path: "payment_verification_rejected",
    component: <RequestForResources />,
    sideBarDisp: true,
    excludeFor: [Role.USER, Role.ADMIN],
  },
  {
    name: "Finance Approval Pending",
    layout: "/admin",
    icon: <MdOutlinePendingActions className="h-6 w-6" />,
    path: "finance_approval_pending",
    component: <RequestForResources />,
    sideBarDisp: true,
    excludeFor: [Role.USER, Role.ADMIN],
  },
  {
    name: "Finance Approval Pending(vacant)",
    layout: "/admin",
    icon: <MdOutlinePendingActions className="h-6 w-6" />,
    path: "finance_approval_vacant_pending",
    component: <RequestForResources />,
    sideBarDisp: true,
    excludeFor: [Role.USER, Role.ADMIN, Role.FINANCE],
  },
  {
    name: "Finance Approved Request",
    layout: "/admin",
    icon: <MdOutlineDone className="h-6 w-6" />,
    path: "finance_Approved_request",
    component: <RequestForResources />,
    sideBarDisp: true,
    excludeFor: [Role.USER, Role.ADMIN],
  },

  // {
  //   name: "Verification Pending",
  //   layout: "/admin",
  //   icon: <MdBarChart className="h-6 w-6" />,
  //   path: "verification_pending",
  //   component: <RequestForResources />,
  //   sideBarDisp: true,
  //   excludeFor: [Role.USER, Role.ADMIN, Role.MANAGER,Role.FINANCE],
  // },

  {
    name: "Approved Requests",
    layout: "/admin",
    icon: <MdDoneAll className="h-6 w-6" />,
    path: "approved_allotment_requests",
    component: <RequestForResources />,
    sideBarDisp: true,
    excludeFor: [Role.USER],
  },

  {
    name: "Approved Requests(vacant)",
    layout: "/admin",
    icon: <MdDoneAll className="h-6 w-6" />,
    path: "approved_allotment_vacant_requests",
    component: <RequestForResources />,
    sideBarDisp: true,
    excludeFor: [Role.USER],
  },

  {
    name: "Rejected Requests",
    layout: "/admin",
    icon: <MdOutlineFreeCancellation className="h-6 w-6" />,
    path: "rejected_requests",
    component: <RequestForResources />,
    sideBarDisp: true,
    excludeFor: [Role.USER],
  },

  {
    name: "Canceled Requests",
    layout: "/admin",
    icon: <MdCancel className="h-6 w-6" />,
    path: "canceled_requests",
    component: <RequestForResources />,
    sideBarDisp: true,
    excludeFor: [Role.USER],
  },

  {
    name: "Details",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: ":page/RequestDetails",
    component: <RequestDetails />,
    sideBarDisp: false,
    excludeFor: [Role.USER, Role.FINANCE],
  },

  {
    name: "Add Resources",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "add_resources",
    component: <AddResources />,
    sideBarDisp: false,
    excludeFor: [Role.USER, Role.FINANCE],
  },
  {
    name: "Add Resources",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: ":page_type/:resource/add_from_csv",
    component: <AddFromCsv />,
    sideBarDisp: false,
    excludeFor: [Role.USER, Role.FINANCE],
  },
  {
    name: "Admin Users",
    layout: "/admin",
    icon: <MdPerson className="h-6 w-6" />,
    path: "get_user/admin_users",
    component: <Users />,
    sideBarDisp: true,
    excludeFor: [
      Role.USER,
      Role.CLEARK,
      Role.MANAGER,
      Role.FINANCE,
      Role.HR_ADMIN,
    ],
  },
  {
    name: "Users",
    layout: "/admin",
    icon: <MdPerson className="h-6 w-6" />,
    path: "get_user/users",
    component: <Users />,
    sideBarDisp: true,
    excludeFor: [Role.USER, Role.FINANCE],
  },
  {
    name: "Add User",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "get_user/:user_type/:action",
    component: <AddUser />,
    sideBarDisp: false,
    excludeFor: [Role.USER, Role.CLEARK, Role.FINANCE],
  },

  {
    name: "Sign In",
    layout: "/auth",
    path: "sign_in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
    secondary: true,
    sideBarDisp: false,
  },

  {
    name: "Sign In",
    layout: "/auth",
    path: "admin_sign_in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
    secondary: true,
    sideBarDisp: false,
  },

  {
    name: "Forgot Password",
    layout: "/auth",
    path: "ForgotPassword",
    icon: <MdLock className="h-6 w-6" />,
    component: <ForgotPassword />,
    sideBarDisp: false,
  },

  {
    name: "Sign Up",
    layout: "/auth",
    path: "sign_up",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignUp />,
    sideBarDisp: false,
  },

  {
    name: "Otp Screen",
    layout: "/auth",
    path: "otp_screen",
    icon: <MdLock className="h-6 w-6" />,
    component: <OtpScreen />,
    secondary: true,
    sideBarDisp: false,
  },
  {
    name: "All Resources",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "all_resources",
    component: <Resources />,
    sideBarDisp: true,
    excludeFor: [Role.USER, Role.FINANCE],
    children: [
      {
        name: "Quarters",
        layout: "/admin",
        path: "all_resources/quarter",
        component: <Resources />,
        icon: <MdBarChart className="h-6 w-6" />,
      },
      {
        name: "Shops",
        layout: "/admin",
        path: "all_resources/shops",
        component: <Resources />,
        icon: <MdBarChart className="h-6 w-6" />,
      },
      // {
      //   name: "Area",
      //   layout: "/admin",
      //   path: "area",
      //   component: <Resources />,
      //   icon: <MdBarChart className="h-6 w-6" />,
      // },
      {
        name: "Multipurpose Hall",
        layout: "/admin",
        path: "all_resources/multipurpose_halls",
        component: <Resources />,
        icon: <MdBarChart className="h-6 w-6" />,
      },
      {
        name: "Officers & Staff Club",
        layout: "/admin",
        path: "all_resources/officer_and_staff_clubs",
        component: <Resources />,
        icon: <MdBarChart className="h-6 w-6" />,
      },
    ],
  },
  {
    name: "Available Resources",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "availble_resources",
    component: <Resources />,
    sideBarDisp: false,
    excludeFor: [Role.USER, Role.FINANCE],
    children: [
      {
        name: "Quarters",
        layout: "/admin",
        path: "availble_resources/quarter",
        component: <Resources />,
        icon: <MdBarChart className="h-6 w-6" />,
      },
      {
        name: "Shops",
        layout: "/admin",
        path: "availble_resources/shops",
        component: <Resources />,
        icon: <MdBarChart className="h-6 w-6" />,
      },
      // {
      //   name: "Area",
      //   layout: "/admin",
      //   path: "area",
      //   component: <Resources />,
      //   icon: <MdBarChart className="h-6 w-6" />,
      // },
      {
        name: "Multipurpose Hall",
        layout: "/admin",
        path: "availble_resources/multipurpose_halls",
        component: <Resources />,
        icon: <MdBarChart className="h-6 w-6" />,
      },
      {
        name: "Officers & Staff Club",
        layout: "/admin",
        path: "availble_resources/officer_and_staff_clubs",
        component: <Resources />,
        icon: <MdBarChart className="h-6 w-6" />,
      },
    ],
  },
  {
    name: "Alloted Resources",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "alloted_resources",
    component: <Resources />,
    sideBarDisp: true,
    excludeFor: [Role.USER, Role.FINANCE],
    children: [
      {
        name: "Quarters",
        layout: "/admin",
        path: "alloted_resources/quarter",
        component: <Resources />,
        icon: <MdBarChart className="h-6 w-6" />,
      },
      {
        name: "Shops",
        layout: "/admin",
        path: "alloted_resources/shop",
        component: <Resources />,
        icon: <MdBarChart className="h-6 w-6" />,
      },
      // {
      //   name: "Area",
      //   layout: "/admin",
      //   path: "area",
      //   component: <Resources />,
      //   icon: <MdBarChart className="h-6 w-6" />,
      // },
      {
        name: "Multipurpose Hall",
        layout: "/admin",
        path: "alloted_resources/multipurpose_halls",
        component: <Resources />,
        icon: <MdBarChart className="h-6 w-6" />,
      },
      {
        name: "Officers & Staff Club",
        layout: "/admin",
        path: "alloted_resources/office_and_staff_clubs",
        component: <Resources />,
        icon: <MdBarChart className="h-6 w-6" />,
      },
    ],
  },

  {
    name: "Officer and Staff Clubs",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: ":page/officer_and_staff_clubs/:action",
    component: <OfficerAndStaffClubForm />,
    sideBarDisp: false,
    excludeFor: [Role.USER, Role.FINANCE],
  },
  {
    name: "Multipurpose Halls",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: ":page/multipurpose_halls/:action",
    component: <MultipurposeHallForm />,
    sideBarDisp: false,
    excludeFor: [Role.USER, Role.FINANCE],
  },
  {
    name: "Shops",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: ":page/shops/:action",
    component: <ShopForm />,
    sideBarDisp: false,
    excludeFor: [Role.USER, Role.FINANCE],
  },
  {
    name: "Quarters",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: ":page/quarter/:action",
    component: <QuarterForm />,
    sideBarDisp: false,
    excludeFor: [Role.USER, Role.FINANCE],
  },

  // todo try to avoid remove four route

  {
    name: "Officer and Staff Clubs",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: ":page/:resourceType/:action/details",
    component: <RequestDetails />,
    sideBarDisp: false,
    excludeFor: [Role.USER, Role.FINANCE],
  },

  {
    name: "Setting",
    layout: "/admin",
    icon: <MdSettings className="h-6 w-6" />,
    path: "setting",
    component: <Resources />,
    sideBarDisp: true,
    excludeFor: [Role.USER, Role.FINANCE],
    children: [
      {
        name: "Charges",
        layout: "/admin",
        path: "setting/charges",
        component: <Charges />,
        icon: <MdOutlinePriceChange className="h-6 w-6" />,
      },
      {
        name: "User Status Report",
        layout: "/admin",
        path: "setting/user_status_report",
        component: <UserReport />,
        icon: <MdOutlinePriceChange className="h-6 w-6" />,
      },
    ],
  },
];
export default routes;
