import Card from "components/card";

const Widget = (props: {
  icon: JSX.Element;
  title: string;
  subtitle?: string;
  iconPos?: string;
  onClickFun?:any
}) => {
  const { icon, title, subtitle, iconPos,onClickFun } = props;
  console.log({ iconPos });
  return (
    <Card extra="!flex-row flex-grow items-center rounded-[20px] pr-[18px]">
      <div
        className={`flex ${
          iconPos === "end" && "flex-row-reverse"
        } justify-between  w-full`}
      >
        <div
          className={`${
            iconPos === "end" ? "justify-between" : "ml-[18px]"
          } flex h-[90px] w-auto flex-row items-center justify-between`}
        >
          <div className="rounded-full  p-3 dark:bg-navy-700" onClick={onClickFun}>
            <span className="flex items-center text-brand-500 dark:text-white">
              {icon}
            </span>
          </div>
        </div>

        <div className="h-50 ml-4 flex w-auto flex-col justify-center">
          <p className="font-dm text-sm font-medium text-gray-600">{title}</p>
          <h4 className="text-xl font-bold text-navy-700 dark:text-white">
            {subtitle}
          </h4>
        </div>
      </div>
    </Card>
  );
};

export default Widget;
