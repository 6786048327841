import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { downloadUserStatusReport } from "services/appServices";

// Define initial values

export const UserReport = () => {
  const [startDate, setStartDate] = useState<string>(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(moment().format("YYYY-MM-DD"));
  const downloadReport = async () => {
    try {
      const response: any = await downloadUserStatusReport({
        startDate: moment(startDate).startOf("day").toISOString(),
        endDate: moment(endDate).endOf("day").toISOString(),
      });

      const { payload, message, error, statusCode } = response.data;
      if (statusCode == 200) {
        toast.info(message);
        if (!payload) return;

        const blob = new Blob([payload], { type: "text/csv;charset=utf-8;" });

        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = "UserStatusReport.csv";
        link.click();

        window.URL.revokeObjectURL(url);
      } else {
        console.log("somethign happend");
        toast.info(message);
        console.log({ error, message });
      }
    } catch (error) {
      console.error("Error downloading the report:", error);
    }
  };
  return (
    <div className="mt-2 p-2 bg-gradient-to-bl from-[#fffdfd] to-[#eefdfe] rounded-md min-h-[50vh]">
      <div className="grid min-h-[5rem] md:grid-cols-2 grid-cols-1 items-center justify-evenly gap-4 overflow-visible rounded-2xl  ">
        <div className="flex flex-col ">
          <label className="text-[#061d0f] hover:text-green-900">
            Select Start Date
          </label>
          <input
            className="rounded-sm border  p-1 text-green-900 shadow-md"
            type="date"
            name="startDate"
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
            value={startDate}
          />
        </div>
        <div className="flex flex-col">
          <label className="text-[#061d0f] hover:text-purple-900">
            Select End Date
          </label>

          <input
            className="rounded-sm border p-1 text-purple-900 shadow-md"
            type="date"
            name="endDate"
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
            value={endDate}
          />
        </div>
      </div>
      <div className="w-full  justify-end items-center flex ">

      <button
        className="px-2 flex-end bg-red-200 mt-6 rounded-sm bg-gradient-to-br from-blue-400 via-blue-600 to-blue-400 p-1 py-2 text-center text-sm text-blue-100 shadow-md  shadow-gray-600 active:to-blue-800 active:shadow-md"
        onClick={() => {
          downloadReport();
        }}
      >
        Download User Status Report
      </button>
      </div>
    </div>
  );
};
