import PieChartCard from "./Pie";
// import { IoMdHome } from "react-icons/io";
// import { IoDocuments } from "react-icons/io5";
import { MdBarChart } from "react-icons/md";

import Widget from "components/widget/Widget";
import { useEffect, useState } from "react";
import {
  getDataForPieChart,
} from "services/appServices";
import { errorToast } from "views/toastConfig";

const Dashboard = () => {
  const [totalByBuildingType, setTotalByBuildingType] = useState<
    [
      {
        buildingType: string;
        allotedCount: number;
        vacantCount: number;
        appliedCount: number;
      }
    ]
  >();

  const [totals, setTotals] = useState<{
    totalApplied: number;
    totalAlloted: number;
    totalVacant: number;
  }>({ totalApplied: 0, totalAlloted: 0, totalVacant: 0 });

  const getResourceData = async () => {
    try {
      const res = await getDataForPieChart();

      if (res?.data?.totals && res?.data?.totalByBuildingType) {
        const { totalByBuildingType, totals } = res.data;
        setTotalByBuildingType(totalByBuildingType);
        setTotals(totals);
        console.log({ totalByBuildingType, totals });
      } else {
        throw new Error(res.data.message);
      }
    } catch (error: any) {
      console.log({ error });
      errorToast(
        error.response?.data?.message ||
          error.message ||
          "Something went wrong!"
      );
    }
  };

  useEffect(() => {
    getResourceData();
  }, []);

  return (
    <div>
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Total"}
          subtitle={
            totals.totalApplied + totals.totalVacant + totals.totalAlloted + ""
          }
        />
        <Widget
          icon={<MdBarChart className="h-6 w-6" />}
          title={"Vacant"}
          subtitle={totals.totalVacant + ""}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Alloted"}
          subtitle={totals.totalAlloted + ""}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Applied"}
          subtitle={totals.totalApplied + ""}
        />

      
      </div>

      <div className="mt-4 grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
        {totalByBuildingType &&
          totalByBuildingType.map((type, index) => (
            <PieChartCard
              key={index}
              label={`BuildingType ${type.buildingType}`}
              pieChartData={[
                type.allotedCount,
                type.vacantCount,
                type.appliedCount,
              ]}
            />
          ))}
      </div>
    </div>
  );
};

export default Dashboard;

// [
//   {
//     $facet: {
//       buildingTypeCounts: [
//         {
//           $group: {
//             _id: "$fields.buildingType",
//             count: { $sum: 1 },
//           },
//         },
//       ],
//       statusCounts: [
//         {
//           $group: {
//             _id: "$status",
//             count: { $sum: 1 },
//           },
//         },
//       ],
//     },
//   },
// ];
